<template>
    <div>
        <div class="row">
            <div class="col-lg-12">
                <div class="panel">
                    <div class="panel-body">
                        <h3 class="panel-title"><img :src="require('@/assets/images/icons/users.svg')" class="panel-icon" alt=""> Thành viên</h3>

                        <div class="row">
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <select class="form-control" v-model="search.limit" @change="getUsers">
                                        <option :value="rows" v-for="rows in $store.state.limitRowsTable">{{ rows | tableRows }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <input type="text" class="form-control" v-model="search.text" placeholder="Tìm kiếm...">
                                </div>
                            </div>
                        </div>

                        <div class="table-responsive">
                            <table class="table table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th>UID</th>
                                        <th>Thao tác</th>
                                        <th>Username</th>
                                        <th>Số dư</th>
                                        <th>Trạng thái</th>
                                        <th>Loại</th>
                                        <th>Đăng nhập</th>
                                        <th>Tạo lúc</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(user, k) in filter">
                                        <td>{{ user.id }}</td>
                                        <td>
                                            <div class="btn-action">
                                                <button class="btn b-info btn-sm" @click="getInfoUser(user)">
                                                <i class="fa fa-edit"></i>
                                                </button>
                                                <!-- button class="btn btn-danger btn-xs btn-outline-danger" @click="deleteUser(user)">
                                                    <i class="fa fa-trash"></i>
                                                </button> -->
                                            </div>
                                        </td>
                                        <td v-html="user.username"></td>
                                        <td>
                                            {{ user.money | numberFormat }}
                                        </td> 
                                        <td>
                                            <span class="label b-success" v-if="user.status == 'active'">Kích hoạt</span>
                                            <span class="label b-danger" v-else>Chưa kích hoạt</span>
                                        </td>
                                        <td v-html="user.role"></td>
                                        <td>{{ user.last_time_login | timeText }}</td>
                                        <td>{{ user.action_time | timeText }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="exampleModalCenter" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                        <h4 class="modal-title" id="exampleModalCenterTitle">Cập nhật thành viên</h4>
                    </div>
                    <div class="modal-body">
                        <form action="" method="POST" @submit.prevent="updateUsers">
                            <div class="form-group">
                                <label>Tài khoản :</label>
                                <input type="text" v-model="user.username" class="form-control" disabled="">
                            </div>
                            <div class="form-group">
                                <label>Mật khẩu :</label>
                                <div class="input-group">
                                    <input :type="[isShowPassword ? 'text' : 'password']" v-model="user.password" class="form-control">
                                    <span class="input-group-btn">
                                        <button class="btn b-danger" type="button" @click="isShowPassword = !isShowPassword"><i class="fa" :class="[isShowPassword ? 'fa-unlock' : 'fa-lock']"></i></button>
                                    </span>
                                </div>
                            </div>
                            <!-- <div class="form-group">
                                <label>Địa chỉ Email :</label>
                                <input type="text" v-model="user.email" class="form-control">
                            </div>
                            <div class="form-group">
                                <label>Facebook ID :</label>
                                <input type="text" v-model="user.fb_id" class="form-control">
                            </div> -->
                            <div class="form-group">
                                <label>Loại tài khoản :</label>
                                <select v-model="user.role" class="form-control">
                                    <option :value="role" v-for="(t, role) in site.roleLists">{{ t }}</option>
                                </select>
                            </div>
                            <div class="form-group">
                                <label>Trạng thái :</label>
                                <select v-model="user.status" class="form-control">
                                    <option :value="role" v-for="(t, role) in site.userStatusLists">{{ t }}</option>
                                </select>
                            </div>
                            <button class="btn b-info btn-block">Cập nhật</button>
                        </form>
                    </div><br>
                </div>
            </div>
        </div>

    </div>
</template>


<script>
    export default {
        data () {
            return {
                users: [],
                user: {},
                search: {
                    limit: -1,
                    text: ''
                },
                isShowPassword: false
            }
        },
        props: ['navbarInfo', 'site'],
        created () {
            // console.log(this.navbarInfo)
            this.getUsers();
        },
        computed: {
            filter () {
                if (this.search.text) {
                    return this.users.filter((x) => {
                        return (
                            x.username.toLowerCase().includes(this.search.text.toLowerCase()) ||
                            x.role.toLowerCase().includes(this.search.text.toLowerCase()) ||
                            x.money.toString().includes(this.search.text.toLowerCase())
                        );
                    });
                }
                return this.users;
            }
        },
        methods: {
            deleteUser (user) {
                let username = '<b>' + user.username + '</b>';
                this.$swal({
                    html: 'Chắc chắn xóa thành viên ' + username + ' ?',
                    imageUrl: require('@/assets/images/icons/users.svg'),
                    imageHeight: '100px',
                    showCancelButton: 1,
                    confirmButtonText: 'Đồng ý'
                }).then((isConfirm) => {
                    if (isConfirm.value) {
                        this.$http.post('admin/users?action=delete', {uid: user.uid}).then(res => {
                            if (res.body.status == 1) {
                                this.users = this.users.filter(x => x.uid !== user.uid);
                            }
                            this.$swal('Thông báo', res.body.message, (res.body.status == 0 ? 'error' : 'success'));
                        });
                    }
                });
            },
            getInfoUser (user) {
                this.$http.post('admin/users?action=info', {uid: user.uid}).then(res => {
                    if (res.body.status == 1) {
                        this.user = res.body.info;
                        this.isShowPassword = false;
                        jQuery('#exampleModalCenter').modal('show');
                    } else {
                        this.$swal('Thông báo', res.body.message, (res.body.status == 0 ? 'error' : 'success'));
                    }
                });
            },
            updateUsers () {
                this.$http.post('admin/users?action=update', this.user).then(res => {
                    if (res.body.status == 1) {
                        this.user = {};
                        this.getUsers();
                        jQuery('#exampleModalCenter').modal('hide');
                    }
                    this.$swal('Thông báo', res.body.message, (res.body.status == 0 ? 'error' : 'success'));
                });
            },
            getUsers () {
                this.$http.post('admin/users?action=lists', this.search).then(res => {
                    this.users = res.body;
                    //this.users = this.users.slice(0, 10);
                });
            }
        }
    }
</script>